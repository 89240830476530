@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.org-chart {
  height: calc(100vh - 115px);
  background-color: #fff;
}

.org-chart > svg {
  height: calc(100vh - 115px);
}

.org-chart > svg div {
  font-family: "Nunito", sans-serif !important;
}

.node-team-member-img {
  width: 35px;
  height: 35px;
}

.expand-btn {
  width: 30px;
  height: 30px;
  margin: auto;
  font-size: 10px;
  color: var(--mainColor);
  background-color: var(--resizeBg);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d3d3d3;
  border-radius: 50%;
  cursor: pointer;
}

.expand-btn > span:last-child {
  display: flex;
}


.overview {
  bottom: 64px;
  right: 6.5px;
  width: 195px;
  height: 123px;
  pointer-events: none;
  cursor: default;
}

/* card css */
.empDetail .card-close-btn {
  position: absolute;
  top: 10px;
  right: 2px;
  width: 30px;
  height: 30px;
  color: #227c9d;
  background-color: #fef9ef;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #227c9d;
  cursor: pointer;
}
.empDetail .card {
  width: 350px;
  height: 500px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0px 25px 5px, rgba(0, 0, 0, 0.05) 0px 0px 0px 0px;
}
.empDetail .card-title {
  text-align: center;
  margin-bottom: 1rem;
}
.empDetail .card-img {
  width: 120px;
  border-radius: 1rem;
}
.empDetail .card-name {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}
.empDetail .card-role {
  margin: 1rem 0;
  font-size: 1.2rem;
}

.empDetail .card-text {
  width: 100%;
  margin: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
}
.empDetail .card-item-label {
  margin: 0.5rem 0;
  font-weight: bold;
}
.empDetail .card-item-value {
  text-align: justify;
}
.empDetail .card-item-team {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.empDetail .card-item-img {
  width: 50px;
  height: 50px;
  margin: 0.2rem;
  border-radius: 50%;
}
.empDetail .card-item-name {
  margin-left: 0.5rem;
  font-weight: bold;
}
.empDetail .card-item-role {
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
.orgCardTitle{
  background-color: #103c5e;
  font-size: 17px;
  font-weight: bold;
  color: #fff;
  padding: 15px;
}


