body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: black;
  text-decoration: none;
}

.form-label {
  font-size: 13px;
  font-weight: 600;
}

.form-control,
.form-select,
label {
  font-size: 13px;
}

.form-select:focus,
.form-control:focus,
.btn:focus-visible {
  box-shadow: none !important;
  border-color: #103c5e;
}

.modal-footer {
  background-color: #dbe7ee;
  padding: 5px 10px;
}

.modal-header {
  background-color: #103c5e;
  padding: 10px 10px;
  color: #fff;
}

.offcanvas-header {
  background-color: #103c5e;
  padding: 6px 10px;
  color: #fff;
}

.modal-title.h4,
.offcanvas-title {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}

/* ScrollBar Css  */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* .btn-outline-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #103c5e;
  --bs-btn-hover-bg: #103c5e;
  --bs-btn-hover-color: #fff;
} */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #d6e5ed;
}

::-webkit-scrollbar-thumb:hover {
  background: #d6e5ed;
}

.Resizer.horizontal {
  border-top: 1px solid var(--bs-border-color);
  border-bottom: 1px solid var(--bs-border-color);
  height: 4px;
  cursor: s-resize;
  transition: 0.2s;
}
.Resizer.vertical {
  border-left: 1px solid var(--bs-border-color);
  border-right: 1px solid var(--bs-border-color);
  width: 4px;
  cursor: e-resize;
  transition: 0.2s;
}
.Resizer.horizontal:hover,
.Resizer.vertical:hover {
  background: var(--bs-border-color);
}
.Pane.horizontal.Pane2 {
  overflow: hidden;
  /* padding-bottom: 25px; */
}
.Pane {
  overflow: auto;
}
.Pane.vertical {
  padding: 0 0px;
}
.resizable-x,
.resizable-y {
  display: flex;
}
.resizable-x {
  height: 100%;
  padding: 5px;
}
.resizable-y {
  flex-direction: column;
  width: -webkit-fill-available;
}
.resizer-x,
.resizer-y {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
}
.resizer-x {
  z-index: 2;
  cursor: col-resize;
  width: 5px;
}
.resizer-x::before,
.resizer-x::after {
  content: "";
  width: 1px;
  height: 16px;
  margin: 1px;
  background: rgb(115, 115, 115);
}
.resizer-xHidden {
  z-index: 2;
  opacity: 0 !important;
  cursor: none !important;
  width: 5px;
}
.resizer-y {
  z-index: 1;
  cursor: col-resize;
  flex-direction: column;
}
.resizer-y::before,
.resizer-y::after {
  content: "";
  width: 16px;
  height: 2px;
  margin: 2px;
  background: var(--white);
}
