@font-face {
  font-family: "CustomFont";
  src: url(../src/gilroy-free/Gilroy-FREE/Gilroy-Light.otf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "CustomFont", gilroy;
}

:disabled,
.btn:disabled {
  cursor: no-drop !important;
  background-color: #e9ecef !important;
  color: #000 !important;
  /* border: 1px solid rgb(151, 149, 149) !important; */
}

#example-collapse-textpop {
  background-color: #f0f9ff;
  border-radius: 4px;
  margin: 1px;
}

.workflow-section {
  display: flex;
  gap: 10px;
}

.date-button-change {
  display: flex;
}

.custom-button {
  border: none;
  padding: 7px 15px;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.custom-button i {
  margin-right: 8px;
}

.workflow-button {
  border: none;
  padding: 7px 15px;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  font-size: 13px;
}

/* Add this CSS to your stylesheet  React*/

.custom-select__control {
  height: calc(20px + 10px + 2px);
  font-size: 13px;
  border-radius: 6px !important;
}

.logout-link:hover {
  background-color: #c77f7f;
  color: #fff;
  transition: 1s;
}

.custom-select__control--is-focused {
  border-color: black !important;
}
/* /////////////////// */

.workflow-button:hover {
  background-color: #e0e0e0;
  color: #000;
}

.workflow-button.active {
  background-color: #103c5e;
  color: #fff;
}

.custom-button:hover {
  background-color: #e0e0e0;
}

.custom-button.active {
  background-color: #103c5e;
  color: #fff;
}

.custom-button:not(:last-child) {
  border-right: 1px solid #ccc;
}

#example-collapse-textpop a {
  list-style-type: none;
  padding-left: 0px;
  font-weight: 600;
  font-size: 13px;
  transition: 1s;
}
#example-collapse-textpop a :hover {
  transition: 1s;
  background-color: #d3d4d4;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.cursor-pointer {
  cursor: pointer;
}

.header_Section {
  background-color: #103c5e;
  width: 100%;
  padding: 6px;
}

.search-bar.form-control {
  border-radius: 0px !important;
  padding: 0px !important;
  width: 160px;
  border-left: none;
  border-right: none;
  border-color: #dee2e6;
}

.search_button:hover {
  background-color: red;
}

.search_button:hover i {
  color: #fff !important;
}

.header_Section img {
  max-width: 100px;
  height: 47px;
  border-radius: 20px;
}
.icon_wrap img {
  border-radius: 50px;
  height: 30px;
  width: 30px;
}

.icon_wrapChatbot img {
  border-radius: 50px;
  height: 40px;
  width: 40px;
}
.bell i {
  color: #fff;
  font-size: 13px;
}
.notification {
  color: white;
  text-decoration: none;
  display: inline-block;
  border-radius: 2px;
}

.notification .badge {
  position: absolute;
  top: 5px;
  right: 6px;
  padding: 3px;
  border-radius: 50%;
  background-color: red;
  color: #000;
  font-size: 10px;
  border: 1px solid #fff;
}
.notificationbar img {
  border-radius: 50px;
  height: 20px;
  width: 20px;
  margin-top: 10px;
}
.notification_clearButton {
  text-align: center;
  font-size: 13px;
  font-weight: 800;
  margin-top: 15px;
}
.notificationbar:hover {
  background: #d3d4d4;
  border-radius: 4px;
  transition: 1s;
  width: 100%;
}
.title_name h6 {
  font-size: 45px;
  margin: 0px;
  color: #fff;
  font-size: 13px;
}

.name span {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.icon_wrap .title_name:hover,
.profileNameActive {
  background-color: #ffff;
  border-radius: 4px;
  transition: 1s;
}

.icon_wrap .title_name:hover h6,
.icon_wrap .title_name:hover i,
.profileNameActive h6,
.profileNameActive i {
  color: #000;
}

.profile .profile_dd {
  width: 170px;
  position: absolute;
  top: 40px;
  z-index: 9;
  border-radius: 5px;
}

.profile_ul {
  padding: 0;
  overflow: hidden;
  margin: 0px;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}
.profile_ul li i {
  color: #232f3e;
  font-size: 13px;
}

.profile.active .profile_ul {
  max-height: 300px;
  transition: max-height 1s ease-in-out;
  overflow: hidden;
  background-color: #ffff;
}

.profile .profile_dd ul li a {
  display: block;
  padding: 10px 9px;
  font-size: 13px;
}

.profile_dd ul li a:hover,
.sidebarmenu1:hover {
  background: #d3d4d4;
}

.logout-link:hover {
  background-color: #885b5b;
}

.smoothCollapse {
  transition: 1s;
}
.smoothCollapse1 {
  transition: 1s;
}
.collapsed {
  transition: 1s;
}

.sidebar_button1 .collapsIcon {
  font-size: 20px;
  cursor: pointer;
  border: none;
  margin: 0px;
  padding: 0px;
  height: 0px;
  background-color: #fff;
}

.sidebar_section {
  font-weight: 600;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
}

.sidebar_section .sidebarmenu {
  width: 100%;
  cursor: pointer;
  padding: 8px 20px;
  white-space: nowrap;
  font-size: 13px;
}

.sidebarsmall-icon :hover {
  background: #d3d4d4;
}

.sidebar_section .sidebarmenu:hover {
  background: #d3d4d4;
}

.sidebarMenuActive:hover,
.sidebarMenuActive {
  background: #d3d4d4;
}

.taskiconMenuActive:hover {
  background: #d3d4d4;
  border-radius: 4px;
  transition: 1s;
}

.taskiconMenuActive:active {
  background: #d3d4d4;
  border-radius: 4px;
}

.workflow-item {
  background: #000000f7;
}

.workflow-item a {
  color: white !important;
}

.workflow-item a:active {
  background-color: transparent;
}
.workflow-item a:hover {
  color: white !important;
  background: #585050 !important;
}

#example-collapse-text ul {
  list-style-type: none;
  padding-left: 0px;
  font-weight: 600;
}

#example-collapse-text ul li {
  padding: 10px 30px;
  font-size: 13px;
}

#example-collapse-text ul li:hover {
  background-color: #d3d4d4;
  color: #000;
}

.workflow_text ul {
  margin: 0px;
  padding: 0px;
}

.sidebar_button button i {
  margin-bottom: 15px;
}

/* search bar aur button ki css */
.search_Bar input {
  border-radius: 0px;
  border-color: black;
  font-size: 14px;
}

.searchcross_button {
  border-radius: 5px 0px 0px 5px;
  border-right: none;
  font-size: 14px;
}

/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> template  button css>>>> */
.custom-button {
  width: 100%;
  border: 0;
  border-radius: 0;
  font-size: 13px;
  background-color: #fff;
  color: black;
  cursor: pointer;
  padding: 10px;
  text-align: center;
}

.custom-button.selected {
  background-color: #103c5e;
  color: white;
}

.custom-button:hover {
  background-color: #103c5e;
  color: #fff;
}
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

.searchcross_search {
  font-size: 14px;
  border-radius: 0 5px 5px 0;
  border-left: none;
}
/* >>>>>>>>>> */

.modal-header .close_modal:hover {
  transform: rotate(180deg);
}
.modal_closeButton .close_modal {
  transition: 1s;
  color: red;
  background: #fff;
  border-radius: 50%;
  font-size: 11px;
  border: 1px solid red;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  cursor: pointer;
}

.teams_button:hover {
  background-color: #103c5e;
}

.teams_button:hover i {
  color: #fff !important;
}

.fiftyVh {
  height: 60vh;
  font-size: 35px;
}
.tableRightContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 182px);
}
.crfHight {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 191px);
}
.modalTitle_button {
  border-radius: 3px;
  transition: 1s;
  font-size: 13px;
}

.icon-container {
  cursor: pointer;
  padding: 4px;
}

.icon-container .fa-trash {
  font-size: 10px;
}
.offcanvas_Close {
  transition: 1s;
  color: red;
  background: #fff;
  border-radius: 50%;
  font-size: 11px;
  border: 1px solid red;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  cursor: pointer;
}
.offcanvas_Close:hover {
  transform: rotate(180deg);
}

.offcanvastext_workflow {
  font-size: 16px;
  font-weight: bold;
  border: 1px solid black;
  background: transparent !important;
  padding-left: 15px;
}
.offcanvastext_workflow:hover {
  background: transparent;
  background-color: transparent !important;
}
.offcanvas_textarea_shadow {
  margin-right: -12px;
}

.workflowOffcanvasTitle_button {
  background-color: #e5ecf0;
  color: #000;
  border: transparent;
  padding-left: 2px;
}
.workflowOffcanvasTitle_button.btn:hover {
  background-color: #c1c1c1;
  color: #000;
  transition: 1s;
}

.workflowOffcanvasbody_button.btn-primary {
  color: #000;
  border: none;
  padding: 10px;
  border-radius: 3px;
  --bs-btn-bg: none;
}

.offcanvas-task.form-control {
  resize: none !important;
  height: 40px;
  padding-left: 17px;
}
.offcanvastext_workflow.form-control:hover {
  border: 1px solid black;
}
.offcanvas-commentCheck {
  padding-top: 8px;
}
.offcanvas-commentCheck-icon {
  background-color: #58a182;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 10px;
  color: white;
  margin-right: 10px;
  padding-top: 3px;
  margin-left: 9px;
}
.workflowOffcanvasbody_button.btn-primary:hover {
  background-color: #fafafa;
}

.offcanvas-collaborators {
  margin-left: 9px;
}

.ofcanvas-collaboration-icon {
  border: 1px dotted black;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 10px;

  margin-right: 10px;
  padding-top: 3px;
}

.ofcanvas-collaboration-icon:hover {
  background-color: #bababa;
  transition: 1s;
}

/* .activityThumbs-dropdowmn.btn-primary {
  --bs-btn-hover-border-color: transparent;
  --bs-btn-color: black;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
}
.activityThumbs-dropdowmn :hover {
  background-color: #f9f8f8;
  transition: 1s;
  border-radius: 4px;
} */

.collaboration-button .btn {
  --bs-btn-color: black;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: transparent;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: transparent;
}
.collaboration-button .btn:hover {
  background-color: #c1c1c1;
  color: #000;
  transition: 1s;
}
.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 300px;
  border-radius: 4px;
  background-color: #fff;
  padding-left: 15px;
}

.custom-dropdown__selected {
  padding: 0px;
  cursor: pointer;
}

.custom-dropdown__options {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
}

.custom-dropdown__options.show {
  display: block;
  width: 284px;
  margin-top: 3px;
}

.custom-dropdown__option {
  padding: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.custom-dropdown__option:hover {
  background-color: #f1f1f1;
}

.custom-dropdown__option .offcanvas-rounded-logo {
  background-color: #ff7f7f;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 10px;
  color: white;
  margin-right: 10px;
  padding-top: 3px;
}
.offcanvas-messageName-icon span {
  background-color: #ff7f7f;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 10px;
  color: white;
  margin-right: 10px;
  padding-top: 3px;
}

.workflowheader:hover {
  border-bottom: 2px solid #103c5e;
}


.workflowheader:hover i,
.workflowheader:hover span {
  color: black;
}

/* .workflowheader.active {
  border-bottom: 2px solid #103c5e;
} */

.border-bottom-active {
  border-bottom: 2px solid #103c5e;
}

.modal-messageName-icon span {
  background-color: #ff7f7f;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 10px;
  color: white;
  margin-right: 10px;
  padding-top: 3px;
}

.custom-dropdown__option .text-content {
  flex-grow: 1;
}

.ofcanvass-deopdown .btn-primary {
  background-color: #ffff;
  border-color: #ffff;
  color: #000;
}
.clear_assignee:hover {
  background-color: #f1f1f1;
  border-radius: 4px;
}

.ofcanvass-deopdown .btn-primary:hover {
  background-color: #f1f1f1;
}

.offcanvas-hoverdate:hover {
  background-color: #f1f1f1;
  border-radius: 3px;
  transition: 1s;
}
.offDate {
  font-size: 16px;
  font-weight: bold;
}

.offCalender-area-logo {
  border: 1px solid red;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 10px;
  color: white;
  padding: 1px;
  padding-top: 3px;
}

.offcanbodytwo {
  overflow: hidden;
}
.date-text-content {
  font-size: 14px;
  color: #333;
}
.offcanvas-description.form-control {
  resize: none !important;
  font-size: 13px;
  font-weight: bold;
  height: 125px;
}

.modal_bodyText input {
  font-size: 13px;
}

.button_type button {
  color: #fff;
  background-color: #103c5e;
  padding: 5px 20px;
  border-radius: 4px;
  font-size: 13px;
  border: none;
}

.milestonemodal_Font label {
  font-size: 5px;
}
.modal_bodyText select {
  font-size: 13px;
  font-weight: 0px;
}
.notificationbar .form-label {
  margin-bottom: 0px;
}
.notification_Bar {
  padding: 9px;
  height: 250px;
  overflow: auto;
}
.footer-section {
  background-color: #103c5e;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_Copy h5 {
  font-size: 15px;
  font-weight: 200;
  color: #fff;
  /* margin: 10px;
  padding: 5px; */
}

.footer_version h6 {
  font-size: 15px;
  color: #fff;
  font-weight: 200;
  /* padding: 8px;
  margin: 10px; */
}

.addworkflowcrontol.form-control {
  font-size: 13px;
  padding: 13px 9px;
  height: 15px;
  width: 236px;
}

/* .inputWorkFlowsection {
  padding: 1px 0px 4px 0px;
} */

.addworkflowcrontol.form-control:hover {
  border-color: black;
}
.admin-main-content-area {
  padding: 1em;
  height: calc(100vh - 95px);
  overflow: auto;
}
.Profile_img label {
  color: var(--text-100);
  font-size: var(--font-size-solid);
  font-weight: 600;
  margin-bottom: 5px;
}

.avatar-upload {
  position: relative;
  max-width: 190px;
  margin: 0px auto;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 40px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  background-color: #fff;
  border: 1px solid var(--transparent_color);
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 #0000001f;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  height: 34px;
  margin-bottom: 0;
  transition: all 0.2s ease-in-out;
  width: 34px;
}

.avatar-upload .avatar-edit input + label:hover {
  background: var(--bg_opacity);
  border-color: var(--border_color);
}

.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: "FontAwesome";
  color: var(--text-100);
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 155px;
  position: relative;
  border-radius: 100%;
  border: 1px solid var(--border-color);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview img {
  border-radius: 50%;
  object-fit: cover;
  height: 154px;
  width: 154px;
}
/* 2 authentication  */
.security_shield {
  text-align: center;
  font-size: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 #63636333;
  width: 220px;
  margin: 0 auto;
  padding: 15px;
}

.security_shield p {
  text-align: center;
  font-size: 13px;
}
.security_shield i {
  font-size: 112px;
}
.security_shieldQr {
  padding: 0px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 4px #63636333;
  width: 220px;
  margin: 0 auto;
}
.otp-input-fields,
.qr__imag {
  margin-top: 15px;
}
.otp-input-fields input {
  border: 1px solid black;
  border-radius: 4px;
  font-size: 13px;
  height: 30px;
  width: 35px;
  text-align: center;
}
/* .otp-input-fields input:hover {
  box-shadow: none;
  border-color: black;
} */
.momdal-body {
  text-align: center;
  padding: 0px;
}
.otp-input-fields {
  gap: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
}
.passwordvisiability {
  position: absolute;
  right: 10px;
  top: 8px;
  font-size: var(--font-size-regular);
  color: var(--accent-200);
  cursor: pointer;
}
/* Apperance page ki css */

.admin-main-content-area {
  padding: 1em;
  height: calc(100vh - 95px);
  overflow: auto;
}

.Account_sidebar ul li:hover,
.leaveOptionBox .optionButton:hover,
.leaveOptionBox .optionActiveButton:hover,
.Account_sidebar a.active li,
.activeSidebarSetting {
  background-color: var(--bg-300);
  /* border-left: 4px solid var(--accent-200) !important; */
  border-radius: 6px 0 0 6px;
  color: var(--text-100);
}

.leaveOptionBox {
  background-color: var(--bg-300);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
  border-top: 2px solid var(--accent-200);
  border-radius: 3px;
  padding: 5px 12px;
  cursor: pointer;
}

.optionButton {
  color: var(--text-100);
  font-size: 13px;
  font-weight: 600;
  margin: 5px 0;
  padding: 6px 10px;
  /* border-left: 4px solid transparent; */
}

.optionActiveButton {
  color: var(--text-100);
  padding: 6px 10px;
  font-size: 13px;
  font-weight: 600;
  margin: 5px 0;
  background-color: var(--bg-300);
  border-left: 4px solid var(--accent-200) !important;
  border-radius: 6px 0 0 6px;
}
#menu-btn {
  align-items: center;
  background-color: #262b2c26;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  height: 30px;
  justify-content: center;
  width: 30px;
}
/* log data page ki css */
/* Table Css ---------------------------- */

.table__for__store .caption {
  font-size: 1.5em;
  margin: 0 0 0.75em;
}

/* table thead tr th {
  background-color: var(--icon_color) !important;
  color: var(--bg_white) !important;
  padding: 0.35em;
} */

table th {
  font-size: 13px;
  font-weight: bolder;
}
table td {
  font-size: 13px;
}

td {
  padding: 5px 5px !important;
}

table {
  margin-bottom: 0 !important;
}

.tableLibrary.custom-table th:last-child {
  width: 100% !important;
  table-layout: fixed;
}

thead.thead-sticky {
  position: sticky;
  top: 0px;
  /* z-index: 9; */
}

.tableLibrary.custom-table {
  width: auto;
  max-width: max-content;
}

.custom-table {
  width: 100%;
  table-layout: fixed;
}

.custom-table.resizing {
  width: 100%;
  max-width: fit-content;
}

tbody tr:nth-child(odd) td {
  background-color: var(--header_bgColor);
}

.tableLibrarySection {
  max-height: calc(100vh - 175px);
}

.headerFixed {
  position: sticky;
  top: 0;
}
.custom-table td,
.custom-table th {
  border: 1px solid #c1b5b55e;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  
}

.custom-table thead tr,th{
  background-color: #B8CFDF !important;
  color: black !important;
  resize: horizontal;
}


.team_table {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding-right: 10px;
}

/* paginatiomn */

.pagination {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin-top: 15px;
}

.paginationResultCount {
  font-weight: bold;
  font-size: 13px;
}

.paginate {
  font-size: 12px;
}

.paginate ul {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 5px;
}

.paginate ul a {
  font-weight: 500;
}

.paginate ul li.selected a {
  font-weight: bold;
}

.paginate ul li.selected {
  background: var(--header_bgColor);
  font-weight: bold;
}

.paginate ul li a {
  padding: 7.2px 10px;
  color: var(--bg_white);
  border: 1px solid black;
  border-radius: 3px;
}

.paginate ul li {
  border: 1px solid var(--icon_color);
  padding: 5.3px 0px;
  border-radius: 3px;
}

.paginate ul li:last-child {
  background-color: var(--icon_color);
  font-weight: bold;
  color: var(--bg_white);
  margin-right: 0;
}

.paginate li {
  font-size: 12px;
  list-style: none;
}
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

.piplinebro {
  display: inline-flex;
  margin-left: 10px;
}

.piplineParent_class {
  min-width: 300px;
  /* padding: 10px; */
  border-radius: 10px 10px 0 0;
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid transparent;
  transition: 0.5s;
  background-image: linear-gradient(#8080800f, #f7f7f763);
}

.piplineParent_class .pipline_box1,
.piplineParent_class .pipline_box2 {
  border-bottom: 1px solid #e5ecf0;
  padding: 10px 10px 5px;
}

.piplinesection_box2 {
  max-height: calc(100vh - 178px);
  height: 100vh;
  overflow: auto;
}

.piplineParent_class:hover {
  border: 1px solid #c7c8c9;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.pipline_box1 h5,
p {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
}

.sidebarmenu1 {
  padding: 10px 9px;
  font-size: 13px;
}

.collapse-text ul {
  padding: 0px;
}

.templatesHeading {
  background-color: #103c5e;
  padding: 1px 12px;
  display: inline-block;
  border-radius: 5px;
  position: absolute;
  top: -12px;
  left: 17px;
  letter-spacing: 1px;
}

.templateorderform_Control {
    width: 37px;
    height: 23px;
    padding: 4px;
    border-radius: 4px;
}


.card_ssection {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  padding: 10px 8px;
  width: 280px;
}

.workflowmodalSubmit.btn:hover {
  border: 1px solid #c7c8c9;
}

/* .Card_Firstelement i {
  display: none;
} */

.Card_Firstelement textarea {
  resize: initial;
}

/* .Card_Firstelement i:hover {
  display: block;
} */

.Card_Firstelement span {
  font-size: 11px;
}

.card_body span {
  font-size: 10px;
  color: red;
}

.workflowmodalSubmit.btn {
  background-color: #e5ecf0;
  color: #000;
  font-size: 12px;
  width: 100%;
  height: 36px;
  border-radius: 6px;
  border: 1px solid transparent;
  margin-bottom: 20px;
}

.button-milestone-top.btn {
  font-size: 13px;
  padding: 10px;
  width: 100px;
}

/* .button-milestone-top.btn:hover {
  background-color: #103c5e;
} */

.button-milestone-last.btn {
  font-size: 13px;
  padding: 10px;
  width: 125px;
  padding-left: 41px;
  padding-right: 37px;
}

.wwnavBtn i:hover {
  background-color: #e5ecf0;
  border-radius: 4px;
  transition: 1s;
}

.wwnavBtn i:active {
  background-color: #e5ecf0;
  border-radius: 4px;
  transition: 1s;
}

/* .card_ssection i {
  display: none;
} */

/* .card_ssection:hover i,
.Card_Firstelement i {
  display: block;
  font-size: 13px;
  color: #5c5a5a;
} */

/* .offcanvas.offcanvas-end {
  width: 720px;
} */

.workflowmodal_button {
  font-size: 13px;
  font-weight: bolder;
}
.offcanvas_short-name {
  border-radius: 50%;
  border: 1px solid black;
}

.second_workflow.form-control {
  border: none;
  background-color: #f9f9f9;
}

.offcanvas_workflow.form-control {
  border: none;
  background-color: #103c5e;
  color: #ffff;
  font-size: 15px;
}
.enter_task.form-control {
  border: none;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ql-editor {
  height: 220px !important;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
/* organization char ki css jo 2 row ke liye */
.react-transform-wrapper {
  width: 100% !important;
  height: 100vh !important;
}

.react-transform-component {
  justify-content: center;
  height: 100vh !important;
  width: 100% !important;
}
/* siddhant organization card css */
.userimg {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.user_sub_parts {
  text-align: left;
}

.chart-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.user_org_type {
  display: flex;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 2px;
  padding: 7px;
  border-radius: 4px;
}
.user_sub_parts {
  text-align: left;
}
.org-chart .user img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.name {
  font-size: 12px;
  font-weight: bold;
}

.org_button {
  margin: 5px 10px 0px 20px;
}

.orgcardfirst_button,
.orgcard_button {
  background-color: #ffff;
  border: none;
  font-size: 12px;
}

/* .card {
  --bs-card-border-width: none;
} */

.card-chevron {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 2px;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: absolute;
  margin: 0 auto;
  bottom: 0;
  left: 50%;
  transform: translate(-14px, 10px);
  z-index: 9;
  cursor: pointer;
}
.zoom-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.org-chart {
  transform-origin: center center;
}

/* tody csss 08/06/2024 */

.draggable-container {
  width: 100%;
  height: 500px;
  /* overflow: hidden;
  position: absolute; */
}
.draggable-element {
  /* position: absolute; */
  cursor: grab;
}

.draggable-element:active {
  cursor: grabbing;
}

.minimap-container {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 120px; /* Adjust as needed */
  height: 120px; /* Adjust as needed */
  border: 1px solid #000;
  background: #fff;
}

.minimap-container img {
  width: 100%;
  height: 100%;
}

.card_buttons {
  display: inline-grid;
  margin-left: 112px;
}
/* >>>>>>>>>>>>>>>08/06/2024 ko sham ke 07:30 PM pe likha code>>>>>>>>>>>>>>>>>>>> */

.profile-card {
  width: 270px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.profile-header {
  background-color: #e5ecf0;
  text-align: center;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-left: 8px;
  margin-bottom: 4px;
}

.profile-body {
  text-align: center;
  padding: 10px 20px;
}

.profile-body h2 {
  font-size: 13px;
  font-weight: bolder;
  text-align: center;
  margin-left: 7px;
  letter-spacing: 1px;
}

.profile-title {
  color: gray;
  margin-bottom: 20px;
  margin-left: 10px;
}

.profile-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.icon-button {
  background-color: #e5ecf0;
  color: black;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 20px;
}

.icon-button i {
  font-size: 18px;
}

/* <login Page ki css> */

.loginimg img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.loginContent h5 {
  font-size: 25px;
  font-weight: bolder;
  text-align: center;
}

.loginside {
  display: grid;
}

.loginButton {
  width: 100%;
  font-size: 13px;
  font-weight: bolder;
}

.signupbutton {
  margin-left: 260px;
  font-size: 13px;
  font-weight: bolder;
}

.loginsection_two {
  place-self: center;
}

.login_button b {
  text-align: center;
  height: 100px;
  width: 80px;
  margin-left: 216px;
}

.signsection_two {
  place-self: center;
}

.table-container {
  max-height: calc(100vh - 126px);
}

table {
  width: 100px;
}
thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.form-text {
  /* border: 1px dashed #dee2e6; */
  height: calc(100vh - 100px);
  width: 100%;
  overflow-x: auto;
}

.form-content {
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-option {
  height: calc(100vh - 100px);
  overflow-y: auto;
}

/* .header-note {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 10px;
  width: 98%;
}
.header-note i {
  color: red;
  font-size: 11px;
} */

.form-task {
  resize: none !important;
  height: 40px;
  padding-left: 17px;
  width: 98%;
  font-size: 20px;
  font-weight: bolder;
}

.form-task:hover {
  border: 1px solid black;
}

::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
}

.clock {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .time {
  font-size: 36px;
  color: green;
  font-family: "Arial", sans-serif;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  transition: all 0.5s cubic-bezier(0.6, 0.2, 0.1, 2);
} */

/* .time {
  font-size: 36px;
  transition: all 0.5s cubic-bezier(0.6, 0.2, 0.1, 2);
} */

.form-time {
  width: 98%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid black;
}

.form-dropdown .btn-primary {
  --bs-btn-color: black;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #dee2e6;
  width: 98%;
}

.form-dropdown .btn-primary:hover {
  background-color: transparent !important;
  color: #000;
  border-color: black;
}
.rating-container i {
  color: black;
  height: 45px;
  width: 35px;
  cursor: pointer;
}

.rating-container i:hover {
  color: red;
}

.terms-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.terms-container input[type="checkbox"] {
  accent-color: #4caf50;
  width: 20px;
  height: 20px;
}

.terms-container label {
  font-size: 13px;
}

.terms-container a {
  color: #4caf50;
  text-decoration: none;
}

.terms-container a:hover {
  text-decoration: underline;
}

.scale {
  display: flex;
}

.box {
  display: none;
}

.color-box {
  width: 40px;
  height: 40px;
  border: 1px solid #000;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-scale {
  min-width: 44px;
  text-align: center;
  font-weight: bold;
  color: black;
  font-family: "Lato", sans-serif;
}

/* Remove initial background color from buttons */
.btn-scale-asc-1,
.btn-scale-desc-10,
.btn-scale-asc-2,
.btn-scale-desc-9,
.btn-scale-asc-3,
.btn-scale-desc-8,
.btn-scale-asc-4,
.btn-scale-desc-7,
.btn-scale-asc-5,
.btn-scale-desc-6,
.btn-scale-asc-6,
.btn-scale-desc-5,
.btn-scale-asc-7,
.btn-scale-desc-4,
.btn-scale-asc-8,
.btn-scale-desc-3,
.btn-scale-asc-9,
.btn-scale-desc-2,
.btn-scale-asc-10,
.btn-scale-desc-1 {
  background-color: transparent;
}
.btn-scale-asc-1.filled,
.btn-scale-desc-10.filled {
  background-color: #ff0000;
}

.btn-scale-asc-2.filled,
.btn-scale-desc-9.filled {
  background-color: #ff3300;
}

.btn-scale-asc-3.filled,
.btn-scale-desc-8.filled {
  background-color: #ff6600;
}

.btn-scale-asc-4.filled,
.btn-scale-desc-7.filled {
  background-color: #ff9900;
}

.btn-scale-asc-5.filled,
.btn-scale-desc-6.filled {
  background-color: #ffcc00;
}

.btn-scale-asc-6.filled,
.btn-scale-desc-5.filled {
  background-color: #ffff00;
}

.btn-scale-asc-7.filled,
.btn-scale-desc-4.filled {
  background-color: #ccff00;
}

.btn-scale-asc-8.filled,
.btn-scale-desc-3.filled {
  background-color: #99ff00;
}

.btn-scale-asc-9.filled,
.btn-scale-desc-2.filled {
  background-color: #66ff00;
}

.btn-scale-asc-10.filled,
.btn-scale-desc-1.filled {
  background-color: #33ff00;
}
footer {
  background-color: #e5ecf0;
  color: black;
  text-align: center;
  padding: 20px 0;
  margin-top: auto;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-note p {
  margin: 5px 0;
}

.footer-note a {
  color: black;
  text-decoration: none;
  margin: 0 10px;
}

.footer-note a:hover {
  text-decoration: underline;
}

#rowButton {
  background-color: red;
  width: 200px;
  margin-left: 40px;
  border-radius: 5px;
  border-color: #fff;
  border-width: 1px;
}

#columnButton {
  background-color: red;
  width: 200px;
  border-radius: 5px;
  border-color: #fff;
  border-width: 1px;
}

.form_column {
  border: none;
}

.signature-box {
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

.signature-box:hover {
  border-color: #000;
}

/* .toolbar {
  margin-bottom: 12px;
} */

.toolbar select {
  padding: 10px;
}

.form_checkinput {
  list-style: none;
  border: none;
  font-size: 13px;
  padding: 3px;
}

.footer-modal-section {
  height: 86px;
  font-size: 14px;
  font-weight: 800;
  padding: 0px;
}

.title_box {
  height: 101px;
}

/* .color .dropdown-toggle {
  border-color: transparent;
  color: black !important;
}

.color .dropdown-toggle:hover,
.color .dropdown-toggle:focus,
.color .dropdown-toggle:active {
  background-color: transparent;
  border-color: transparent;
  color: black !important;
}

.dropdown-item {
  color: black !important;
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
  background-color: transparent !important;
  color: black !important;
} */

.signature-bold {
  cursor: pointer;
  padding: 7px 15px;
  display: inline-block;
}
.signature-bold:hover {
  background-color: #e5ecf0;
  color: #000;

  transition: 1s;
}
.modal_heading_text {
  padding: 10px;
  cursor: pointer;
}

.form_tableheader {
  background-color: #fff;
}

.form_tablecolumn .form_column {
  padding: 5px;
}

.header-input:focus {
  background-color: #e5ecf0;
}

.fixed-buttongroup {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 9;
}

.date-buttongroup .btn {
  font-size: 14px;
  padding: 5px 11px;
}

.form-check-input[type="checkbox"] {
  border-color: #000;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: black;
}

.form-check-input:checked {
  background-color: #000;
}

.dateform-control.form-control:focus {
  width: 100%;
}

.date-icon input {
  height: 25px;
  width: 25px;
  border: none;
  padding: 5px;
  display: flex;
  background: transparent;
}

.date-icon input:hover,
.date-icon i:hover {
  background-color: #e5ecf0;
  cursor: pointer;
  border-radius: 3px;
}

.date-icon i {
  padding: 5px 8px;
  font-size: 13px;
}

.date-icon {
  padding: 0px 10px;
}

.checkbox-popover.popover-body {
  padding: 15px;
  width: 280px;
  height: 251px;
  font-size: 13px;
  font-weight: bold;
}

.form-check-input:checked {
  border-color: transparent;
}

.access-radiobox {
  margin-top: 3px;
}

.inline-checkboxes .checkbox-item {
  display: inline-block;
  margin-right: 10px; /* Adjust as necessary */
}
.vr {
  height: 30px;
}

.dropdown-option.form-control {
  padding: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.list-group.button {
  font-size: 13px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.flex-inline {
  flex-direction: row;
}

.input-group .btn {
  padding: 4px;
}

.uploadfiledropdown .btn-primary {
  background-color: transparent;
  color: #000;
  width: 100%;
  font-size: 13px;
  --bs-btn-hover-bg: transparent;
  --bs-btn-border-color: #000;
  --bs-btn-hover-border-color: #000;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: #000;
  text-align: left;
}

.checkbox-control:focus {
  border-color: #dee2e6;
}
.apply-button-templates {
  font-size: 13px;
}

.css-t3ipsp-control {
  border-color: #000 !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  line-height: 1.5;
}

.css-1fdsijx-ValueContainer {
  border-radius: 10px !important;
  /* line-height: 1.5 !important;*/
  height: 35px;
}
.css-13cymwt-control {
  min-height: 35px;
  border-radius: 6px;
  font-size: 13px;
}
.css-d7l1ni-option {
  cursor: default;
  display: block;
  font-size: inherit;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #e5ecf0 !important;
  color: inherit;
  padding: 8px 12px;
  box-sizing: border-box;
}
/* client Css */

.resizable-x,
.resizable-y {
  display: flex;
  overflow: hidden;
}
.resizable-y {
  flex-direction: column;
}
.resizer-x,
.resizer-y {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.resizer-x {
  z-index: 2;
  cursor: col-resize;
}
.resizer-y {
  z-index: 2;
  cursor: row-resize;
  flex-direction: column;
}
.resizer-x::before,
.resizer-x::after,
.resizer-y::before,
.resizer-y::after {
  content: "";
  margin: 1px;
  background: gray;
}
.resizer-x::before,
.resizer-x::after {
  width: 1px;
  height: 20px;
}
.resizer-y::before,
.resizer-y::after {
  width: 20px;
  height: 1px;
}

.resizer {
  display: inline-block;
  width: 5px;
  cursor: col-resize;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.submitbutton {
  background: transparent;
  border: 1px solid black;
  border-bottom: none;
  color: black;
  border-radius: 4px;
  font-size: 13px;
  padding: 5px 13px;
  transition: 1s;
  align-self: flex-end;
}
.submitbutton:focus {
  background: transparent;
  color: var(--blue);
}

.submitbutton:hover {
  background: #5c636a;
  color: #fff;
}

.clientButton.btn {
  font-size: 13px;
  padding: 3px 13px;
  align-self: flex-end;
  min-width: 100px;
  position: sticky;
  color: #000;
  background-color: #e4e9ed !important;
  top: 0;
}

.clientButton.btn.active {
  background-color: #103c5e !important;
  color: #fff;
}

.clientButton.btn:hover {
  background-color: #103c5e !important;
  color: #fff;
}
.app-container.fullscreen {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.toolbar-full-screen {
  position: sticky;
  margin-top: 17px;
}

/* .table-container {
  max-height: 400px;
  overflow-y: auto;
}

thead th {
  position: sticky;
  top: 0;
  background-color: #e5ecf0;
  z-index: 1;
} */

.selected td {
  background-color: #d3d4d4 !important;
}

.workflowMenuList:hover {
  background: #d3d4d4;
}

/* Right Click CSS  */

.react-contextmenu-wrapper {
  font-size: small;
  font-weight: initial;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Footer_section h2 {
  font-size: 20px;
}

nav.react-contextmenu {
  min-width: 200px;
  border-radius: 4px;
  background-color: rgb(0 0 0 / 74%);
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.react-contextmenu .react-contextmenu-item:hover {
  background: #000;
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
  background-color: #000;
}

.react-contextmenu .react-contextmenu-item {
  padding: 3px 15px;
  cursor: pointer;
}

.react-contextmenu .react-contextmenu-item i {
  font-size: 12px;
}

.react-contextmenu-item span {
  margin-left: 10px;
  font-size: 13px;
}

.react-contextmenu-item svg {
  font-size: 14px;
}

.form-check-input:checked[type="checkbox"],
.form-check-input[type="checkbox"] {
  margin-top: 6px !important;
}

.status-select {
  border: none;
  background: none;
  font-weight: bold;
}

.status-select.active {
  color: green;
}

.status-select.inactive {
  color: red;
}

.status-select option {
  color: black;
}

.status-select:focus-visible {
  border: transparent;
}

/* Add your custom CSS styles here */

.custom-table tr:hover .delete-icon {
  visibility: visible;
}

.delete-icon {
  visibility: hidden;
  cursor: pointer;
  color: red;
}

.quatation.input-group-text {
  padding: 5px;
  font-size: 13px;
}

.add_sponsor_field .input-group-text {
  font-size: 11px;
  background-color: #dedede;
  padding: 3px 8px;
  border-radius: 3px;
  border-color: #dedede;
}

/* Analytics */

.graph__title p {
  font-size: 15px;
  font-weight: 600;
}

.widget-data {
  padding: 0 15px;
}

.analytics_card {
  background-color: #e4e9ed;
  border-radius: 3px;
}

.analytics_icon {
  align-items: center;
  background-color: #103c5e;
  color: #fff;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 25px;
  width: 40px;
}

.chart-background {
  background-color: #e4e9ed;
}

.chart-card {
  height: 50px;
}
.cardTable {
  background-color: #103c5e;
  color: #fff;
}

.button_type button {
  color: #fff;
  background-color: #103c5e;
  padding: 5px 20px;
  border-radius: 4px;
  font-size: 13px;
  border: none;
}

.gautam_li div:hover {
  background-color: #d3d4d4;
  cursor: pointer;
}

.gautam_li :active {
  background-color: #d3d4d4;
  color: black;
}

/* 
restrication Page Coding -- */

.container {
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  text-align: center;
}

.forbidden-code {
  font-size: 120px;
  font-weight: bold;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

/* Quoutation Client Page ki css  */

.quoutationBackground {
  background-color: #e4e9ed;
  color: #000;
  padding-top: 10px;
}
.quotationSidebar {
  padding: 10px;
}

/* new multiSelect */
.searchWrapper {
  border-radius: 4px !important ;
  border: 1px solid #ccc !important;
  min-height: 22px;
  padding: 6px !important;
  position: relative !important;
}

.chip {
  align-items: center !important;
  background: #103c5e !important;
  border-radius: 5px !important;
  color: #fff !important;
  display: inline-flex !important;
  font-size: 13px !important;
  line-height: 19px !important;
  margin-bottom: 5px !important;
  margin-right: 5px !important;
  padding: 4px 10px !important;
}

.highlightOption {
  background: #103c5e !important;
  color: #fff !important;
  font-size: 13px !important;
}

.multiSelectContainer li:hover {
  background: #103c5e !important;
  color: #fff !important;
  cursor: pointer !important;
}

.multiSelectContainer li {
  padding: 5px !important;
}

.multiSelectContainer ul {
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  font-size: 13px !important;
  display: block !important;
  margin: 0 !important;
  max-height: 250px !important;
  overflow-y: auto !important;
  padding: 0 !important;
}

.multiSelectContainer {
  position: relative !important;
  text-align: left !important;
  width: 100% !important;
}

.multiSelectContainer input {
  background: transparent !important;
  border: none !important;
  margin-top: 3px !important;
  font-size: 13px !important;
}
.qutationsInputBox {
  font-size: 13px;
  height: 24px;
  border-radius: 5px;
}

.inputforSecelct input::-webkit-outer-spin-button,
.inputforSecelct input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.qutationCard {
  padding-right: 7px;
}

.progress-bar {
  background-color: #103c5e;
}

.cardHoverAdd {
  transition: 0.5s;
}
.cardHoverAdd:hover {
  background-color: #0003 !important;
}
.step {
  font-size: 12px;
  display: contents;
  position: relative;
}
.step::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ddd;
  z-index: -1;
}
.step.active > div {
  color: #fff;
  background-color: #103c5e !important;
  border: 1px solid #103c5e !important;
}
.step.active::before {
  background-color: #103c5e;
}

.fontSize12 {
  font-size: 12px !important;
}

.custom-button {
  background-color: #1f4b65;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 3px 8px;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 130px;
}
/* .custom-select {
  border: 1px solid #cccccc;
  padding: 8px;
  border-radius: 5px;
} */

.taskAssignButton.btn {
  background-color: #1f4b65;
  border-color: #1f4b65;
}

.taskAssignButton.btn:active {
  background-color: #1f4b65;
  border-color: #1f4b65;
}

.custom_badge.badge {
  border-radius: 4px !important;
  font-weight: 200;
}

/* .customCummontOPtion {
  padding-left: 35px;
  position: relative;
} */

.menu-section {
  top: -150px;
  background-color: #fff;
  width: 190px;
}

.menu-title {
  font-weight: bold;
  margin-bottom: 10px;
  color: #555;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-list li {
  padding: 4px 3px;
  margin-bottom: 2px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 13px;
}

.menu-list li:hover {
  background-color: #f3f3f3;
}

.menu-list .with-shortcut {
  display: flex;
  justify-content: space-between;
}

.shortcut {
  font-size: 10px;
  color: #888;
}

.templateTable{
  padding: 2px;
    height: 30px;
    border-color: #a0b2c0;
    background-color: #a0b2c0;
}

/* >>>>>>>>>>>>>>>>>>>Workflow Calender KI CSS */


.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
  background-color:#103c5e ;
  border-color: black;
  color: white;
}

.fc .fc-button-primary {
  background-color: #103c5e ;
  border-color: black;
  color: #fff;
}

.fc .fc-button-primary:hover {
  background-color: #103c5e ;
  color: #fff;
}

.fc .fc-media-screen .fc-direction-ltr .fc-theme-standard{
  width: 115px !important;
}

.event-pending {
  background-color: #ffffc5 !important;
  color: black !important;
}

.event-finished {
  background-color: #90EE90 !important;
  color: white !important;
}

.event-not-started {
  background-color: #FF7F7F !important;
  color: white !important;
}


.fc .fc-scroller-harness-liquid {
  height: calc(100vh - 240px) !important;
}

.fc-h-event .fc-event-title-container {
  height: 56px;
}

.fc-h-event .fc-event-title{
  font-size: 14px;
}

 .header-container {
  display: flex;
  background-color: #6c7a89;
  width: 750px;
}

.tablesss-container {
  width: 100%;
  overflow-x: auto;
}

.customModal-table {
  width: 100%;
  border-collapse: collapse;
}

.customModal-table th {
  padding: 5px;
  text-align: left;
  color: white;
  font-weight: bold;
  background-color: #9fc5e0 !important;
  position: relative;
  border: 1px solid white;
}

.message-container {
  border-bottom: 1px solid #eaeaea;
}

.avatar-img {
  width: 40px;
  height: 40px;
}

.message-content h6 {
  font-size: 16px;
  font-weight: bold;
}

.message-content p {
  font-size: 14px;
}

.message-contentes p {
  font-size: 11px;
}

.message-content small {
  font-size: 12px;
}



.chat_hover_effect:hover{
  background-color: #f0f0f0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
/* /// */

.avatar-container {
  position: relative;
}

.status-indicator {
  position: absolute;
  bottom: 0;
  right: 6px;
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  border: 2px solid white;
}

.status-indicatorred{
  position: absolute;
  bottom: 0;
  right: 6px;
  width: 10px;
  height: 10px;
  background-color: rgb(138, 13, 4);
  border-radius: 50%;
  border: 2px solid white;
}


/* /////////////// */


.last-seen {
  font-size: 10px;
  color: gray;
}

.chat-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
}

.message {
  max-width: 35%;
  padding: 6px;
  margin-bottom: 25px;
  border-radius: 20px;
  position: relative;
}

.messageHello{
  max-width: 8%;
  padding: 6px;
  margin-bottom: 25px;
  border-radius: 20px;
  position: relative;
}

.messageHello2{
  max-width: 18%;
  padding: 6px;
  margin-bottom: 25px;
  border-radius: 20px;
  position: relative;
}

.receiver {
  background-color: #ececec;
  color: black;
  align-self: flex-start;
}

.sender {
  background-color: #103c5e;
  color: white;
  align-self: flex-end;
  margin-left: auto;
}

.time {
  display: block;
  font-size: 12px;
  color: #999;
  margin-top: 5px;
  text-align: right;
}



.time, .sender .time {
  position: absolute;
  bottom: -18px;
  font-size: 8px;
}

.sender {
  text-align: right;
  border-top-right-radius: 0;
}

.receiver {
  border-top-left-radius: 0;
}

.receiver .time{
  position: absolute;
  bottom: -18px;
  font-size: 8px;
}

/* ///////////////////// */

.message-input-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 15px;
}

.message-input-field {
  flex: 1;
  background-color: transparent;
  padding-left: 15px;
  height: 35px;
}

.message-icons {
  display: flex;
  gap: 15px;
  margin-right: 15px;
}

.message-icon {
  color: #103c5e;
  font-size: 1.2rem;
  cursor: pointer;
  padding-left: 4px;
}

.microphone-button {
  background-color: #103c5e;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}


.Account_sidebar ul li:hover,
.leaveOptionBox .optionButton:hover,
.leaveOptionBox .optionActiveButton:hover,
.Account_sidebar a.active li,
.activeSidebarSetting {
  background-color: var(--bg-300);
  border-left: 4px solid var(--accent-200) !important;
  border-radius: 6px 0 0 6px;
  color: var(--text-100);
}


